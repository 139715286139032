import React, { useState, useRef } from "react";
import axios from './../../api/axios';

const ChangeFeatureForm = () => {
  // State Management
  const [currentFeatureName, setCurrentFeatureName] = useState("");
  const [isCurrentFeatureNameValid, setIsCurrentFeatureNameValid] = useState(null);
  const [isTypingCurrentFeatureName, setIsTypingCurrentFeatureName] = useState(false);

  const [requestedChange, setRequestedChange] = useState("");
  const [isRequestedChangeValid, setIsRequestedChangeValid] = useState(null);
  const [isTypingRequestedChange, setIsTypingRequestedChange] = useState(false);

  const [category, setCategory] = useState("");
  const [priority, setPriority] = useState("");

  const [attachments, setAttachments] = useState([]);
  const [areAttachmentsValid, setAreAttachmentsValid] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const fileInputRef = useRef(null);

  // Validation Functions
  const validateCurrentFeatureName = (value) => {
    setIsTypingCurrentFeatureName(true);
    setTimeout(() => {
      setIsTypingCurrentFeatureName(false);
      setIsCurrentFeatureNameValid(value.trim().length >= 3 && value.trim().length <= 150);
    }, 500);
  };

  const validateRequestedChange = (value) => {
    setIsTypingRequestedChange(true);
    setTimeout(() => {
      setIsTypingRequestedChange(false);
      setIsRequestedChangeValid(value.trim().length >= 3 && value.trim().length <= 8000);
    }, 500);
  };

  const validateAttachments = (files) => {
    const totalSize = Array.from(files).reduce((sum, file) => sum + file.size, 0);
    setAreAttachmentsValid(totalSize <= 23 * 1024 * 1024); // 23 MB
  };

  // Handlers
  const handleCurrentFeatureNameChange = (e) => {
    const value = e.target.value;
    setCurrentFeatureName(value);
    validateCurrentFeatureName(value);
  };

  const handleRequestedChangeChange = (e) => {
    const value = e.target.value;
    setRequestedChange(value);
    validateRequestedChange(value);
  };

  const handleChangeFeatureSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("currentFeatureName", currentFeatureName);
    formData.append("requestedChange", requestedChange);
    formData.append("category", category);
    formData.append("priority", priority);

    attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    try {
      const response = await axios.post("/api/change-feature", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        setSubmissionStatus("Change request submitted successfully!");

        // Reset all states
        setCurrentFeatureName("");
        setRequestedChange("");
        setCategory("");
        setPriority("");
        setAttachments([]);
        setIsCurrentFeatureNameValid(null);
        setIsRequestedChangeValid(null);
        setAreAttachmentsValid(null);

        if (fileInputRef.current) fileInputRef.current.value = "";

        // Clear success message after 5 seconds
        setTimeout(() => setSubmissionStatus(""), 5000);
      }
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || "An error occurred. Please try again later.";
      setSubmissionStatus(`Submission failed: ${errorMessage}`);

      // Clear error message after 5 seconds
      setTimeout(() => setSubmissionStatus(""), 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="feature-form" onSubmit={handleChangeFeatureSubmit}>
      <label>
        Current Feature Name:
        {isTypingCurrentFeatureName ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : isCurrentFeatureNameValid ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : isCurrentFeatureNameValid === false ? (
          <i className="fa fa-times" style={{ color: "red" }}></i>
        ) : null}
        <div className="input-wrapper">
          <input
            type="text"
            name="currentFeatureName"
            value={currentFeatureName}
            onChange={handleCurrentFeatureNameChange}
            placeholder="E.g., User Profiles, Navigation Menu"
            required
          />
        </div>
      </label>

      <label>
        Requested Change:
        {isTypingRequestedChange ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : isRequestedChangeValid ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : isRequestedChangeValid === false ? (
          <i className="fa fa-times" style={{ color: "red" }}></i>
        ) : null}
        <div className="input-wrapper">
          <textarea
            name="requestedChange"
            value={requestedChange}
            onChange={handleRequestedChangeChange}
            placeholder="Describe what needs to change and why"
            required
          ></textarea>
        </div>
      </label>

      <label>
        Category:
        <select name="category" required onChange={(e) => setCategory(e.target.value)}>
          <option value="">Select Category</option>
          <option value="Design">Design (Appearance, Layout, or Visuals)</option>
          <option value="Functionality">Functionality (Features or How Things Work)</option>
          <option value="Performance">Performance (Speed or Responsiveness)</option>
          <option value="Integration">Integration (Connecting with Other Tools or Services)</option>
          <option value="Other">N/A, Other, or Unsure</option>
        </select>
      </label>

      <label>
        Priority Level:
        <select name="priority" required onChange={(e) => setPriority(e.target.value)}>
          <option value="">Select Priority</option>
          <option value="Low">Low</option>
          <option value="Normal">Normal</option>
          <option value="High">High</option>
        </select>
      </label>

      <div className="attachments-wrapper">
        <label htmlFor="attachments">Attachments (Optional):</label>
        <div className="input-wrapper">
          <input
            ref={fileInputRef}
            id="attachments"
            type="file"
            name="attachments"
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files);
              setAttachments(files);
              validateAttachments(files);
            }}
          />
        </div>
        <small>Max total size: 23 MB</small>
        {attachments.length > 0 && (
          <div className="attachments-preview">
            {attachments.map((file, index) => (
              <div key={index} className="attachment-item">
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="attachment-preview"
                  />
                ) : (
                  <div className="attachment-file">{file.name}</div>
                )}
                <div className="remove-attachment-wrapper">
                  <button
                    type="button"
                    className="remove-attachment"
                    onClick={() => {
                      const updatedAttachments = attachments.filter((_, i) => i !== index);
                      setAttachments(updatedAttachments);
                      validateAttachments(updatedAttachments);
                    }}
                  >
                    <i className="fa-solid fa-x" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <button
        type="submit"
        className={`submit-button ${isSubmitting ? "loading" : ""}`}
        disabled={
          !isCurrentFeatureNameValid ||
          !isRequestedChangeValid ||
          areAttachmentsValid === false ||
          isSubmitting
        }
      >
        {isSubmitting ? (
          <>
            <i className="fa fa-spinner fa-spin"></i>&nbsp; Submitting Request...
          </>
        ) : (
          "Submit Request"
        )}
      </button>

      {submissionStatus && <p>{submissionStatus}</p>}
    </form>
  );
};

export default ChangeFeatureForm;