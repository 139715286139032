import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './../../context/AuthProvider';
import './styles/AdminDashboard.css';

const AdminDashboard = () => {
    const { auth, hasAccess } = useAuth();

    const childcareRole = auth.user.role === 'childcare'; // Determine if headings should be displayed

    return (
        <>
            <h1 className='admin-welcome'>
                Welcome <span className='dashboard-username'>{auth.user.username}</span>!
            </h1>
            <div className='admin-dashboard-wrapper'>
                <div className='row-wrapper'>
                    {hasAccess('/admin/messages') && (
                        <Link to='/admin/messages' className='dashboard-button red'>
                            <i className="fa-solid fa-message"></i> Messages & Prayer and Directory Requests
                        </Link>
                    )}
                </div>

                {/* Conditionally render Manage Users button based on role */}
                {hasAccess('/admin/users') && (
                    <div className='row-wrapper'>
                        <Link to='/admin/users' className='dashboard-button red'>
                            <i className="fa-solid fa-user"></i> Manage Users
                        </Link>
                    </div>
                )}

                {hasAccess('/admin/feature-requests') && (
                    <div className='row-wrapper'>
                        <Link to='/admin/feature-requests' className='dashboard-button red'>
                            <i className="fa-solid fa-lightbulb"></i> Website Feature Requests
                        </Link>
                    </div>
                )}



                <div className='admin-dashboard'>
                    <div className='dashboard-column'>
                        {!childcareRole ? <h3>Worship</h3> : <></>}
                        {hasAccess('/admin/past-sessions') && (
                            <Link to='/admin/past-sessions' className='dashboard-button'>
                                Manage Past Services
                            </Link>
                        )}
                        {hasAccess('/admin/daily-readings') && (
                            <Link to='/admin/daily-readings' className='dashboard-button'>
                                Manage Daily Readings
                            </Link>
                        )}
                        {hasAccess('/admin/verse-of-the-day') && (
                            <Link to='/admin/verse-of-the-day' className='dashboard-button'>
                                Manage Verse of the Day
                            </Link>
                        )}
                    </div>

                    <div className='dashboard-column'>
                        {childcareRole ? <div style={{marginTop: '2rem'}}></div> : <h3>Other</h3>}
                        {hasAccess('/admin/announcements') && (
                            <Link to='/admin/announcements' className='dashboard-button'>
                                Manage Announcements
                            </Link>
                        )}
                        {hasAccess('/admin/church-staff') && (
                            <Link to='/admin/church-staff' className='dashboard-button'>
                                Manage Church Staff
                            </Link>
                        )}
                        {hasAccess('/admin/childcare-staff') && (
                            <Link to='/admin/childcare-staff' className='dashboard-button'>
                                Manage Childcare Staff
                            </Link>
                        )}
                    </div>

                   
                    <div className='dashboard-column ministries'>
                        {!childcareRole ? <h3>Ministries and Outreach</h3> : <></>}
                        {hasAccess('/admin/operation-christmas-child') && (
                            <Link to='/admin/operation-christmas-child' className='dashboard-button red'>
                                <i className="fa-solid fa-gifts"></i>&nbsp;&nbsp;Operation Christmas Child
                            </Link>
                        )}
                        {hasAccess('/admin/first-responders') && (
                            <Link to='/admin/first-responders' className='dashboard-button red'>
                                <i className="fa-solid fa-star-of-life"></i>&nbsp;&nbsp;First Responders
                            </Link>
                        )}
                        {hasAccess('/admin/mission-trips') && (
                            <Link to='/admin/mission-trips' className='dashboard-button red'>
                                <i className="fa-regular fa-compass"></i>&nbsp;&nbsp;Mission Trips
                            </Link>
                        )}
                        {hasAccess('/admin/common-grounds') && (
                            <Link to='/admin/common-grounds' className='dashboard-button red'>
                                <i className="fa-solid fa-utensils"></i>&nbsp;&nbsp;Common Grounds
                            </Link>
                        )}
                        {hasAccess('/admin/september-club') && (
                            <Link to='/admin/september-club' className='dashboard-button red'>
                                <i className="fa-solid fa-utensils"></i>&nbsp;&nbsp;September Club
                            </Link>
                        )}
                        {hasAccess('/admin/holidays') && (
                            <Link to='/admin/holidays' className='dashboard-button red'>
                                <i className="fa-solid fa-heart"></i>&nbsp;&nbsp;Holidays
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminDashboard;
