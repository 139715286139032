import React, { useState } from "react";
import "./styles/AdminFeatureRequests.css";
import AddFeatureForm from "./AddFeatureForm";
import ChangeFeatureForm from "./ChangeFeatureForm";
import RemoveFeatureForm from "./RemoveFeatureForm";

const AdminFeatureRequests = () => {
    const [selectedAction, setSelectedAction] = useState("add");

    const renderForm = () => {
        switch (selectedAction) {
            case "add":
                return <AddFeatureForm />;
            case "change":
                return <ChangeFeatureForm />;
            case "remove":
                return <RemoveFeatureForm />;
            default:
                return null; // Return null when no action is selected
        }
    };

    return (
        <div className="admin-page">
            <h2>What would you like to do?</h2>
            <div className="button-group">
                <button
                    className={`action-button ${selectedAction === "add" ? "selected" : ""}`}
                    onClick={() => setSelectedAction("add")}
                >
                    <i className="fa-solid fa-plus add"></i>
                    &nbsp; Add New Feature
                </button>
                <button
                    className={`action-button ${selectedAction === "change" ? "selected" : ""}`}
                    onClick={() => setSelectedAction("change")}
                >
                    <i className="fa-solid fa-sliders alt"></i>
                    &nbsp; Change a Feature
                </button>
                <button
                    className={`action-button ${selectedAction === "remove" ? "selected" : ""}`}
                    onClick={() => setSelectedAction("remove")}
                >
                    <i className="fa-solid fa-xmark main"></i>
                    &nbsp; Remove a Feature
                </button>
            </div>
            <hr className="feature-separator" />
            <div className="form-container">{renderForm()}</div>
        </div>
    );
};

export default AdminFeatureRequests;