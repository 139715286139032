import React, { useState, useRef } from "react";
import axios from './../../api/axios';

const AddFeatureForm = () => {
  // State Management
  const [featureName, setFeatureName] = useState("");
  const [isFeatureNameValid, setIsFeatureNameValid] = useState(null);
  const [isTypingFeatureName, setIsTypingFeatureName] = useState(false);

  const [description, setDescription] = useState("");
  const [isDescriptionValid, setIsDescriptionValid] = useState(null);
  const [isTypingDescription, setIsTypingDescription] = useState(false);

  const [category, setCategory] = useState("");
  const [priority, setPriority] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [areAttachmentsValid, setAreAttachmentsValid] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const fileInputRef = useRef(null);

  // Validation Functions
  const validateFeatureName = (value) => {
    setIsTypingFeatureName(true);
    setTimeout(() => {
      setIsTypingFeatureName(false);
      setIsFeatureNameValid(value.trim().length >= 3 && value.trim().length <= 150);
    }, 500);
  };

  const validateDescription = (value) => {
    setIsTypingDescription(true);
    setTimeout(() => {
      setIsTypingDescription(false);
      setIsDescriptionValid(value.trim().length >= 3 && value.trim().length <= 8000);
    }, 500);
  };

  const validateAttachments = (files) => {
    const totalSize = Array.from(files).reduce((sum, file) => sum + file.size, 0);
    setAreAttachmentsValid(totalSize <= 23 * 1024 * 1024); // Convert MB to bytes
  };

  // Handlers
  const handleFeatureNameChange = (e) => {
    const value = e.target.value;
    setFeatureName(value);
    validateFeatureName(value);
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    validateDescription(value);
  };

  const handleAddFeatureSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("featureName", featureName);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("priority", priority);

    attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    try {
      const response = await axios.post("/api/add-feature", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        setSubmissionStatus("Feature request submitted successfully!");
        // Reset all states
        setFeatureName("");
        setDescription("");
        setCategory("");
        setPriority("");
        setAttachments([]);
        setIsFeatureNameValid(null);
        setIsDescriptionValid(null);
        setAreAttachmentsValid(null);

        if (fileInputRef.current) fileInputRef.current.value = "";

        setTimeout(() => setSubmissionStatus(""), 5000);
      }
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || "An error occurred. Please try again later.";
      setSubmissionStatus(`Submission failed: ${errorMessage}`);
      setTimeout(() => setSubmissionStatus(""), 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="feature-form" onSubmit={handleAddFeatureSubmit}>
      <label>
        Feature Name:
        {isTypingFeatureName ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : isFeatureNameValid ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : isFeatureNameValid === false ? (
          <i className="fa fa-times" style={{ color: "red" }}></i>
        ) : null}
        <div className="input-wrapper">
          <input
            type="text"
            name="featureName"
            value={featureName}
            onChange={handleFeatureNameChange}
            placeholder="E.g., Dark Mode, Search Bar"
            required
          />
        </div>
      </label>

      <label>
        Description:
        {isTypingDescription ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : isDescriptionValid ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : isDescriptionValid === false ? (
          <i className="fa fa-times" style={{ color: "red" }}></i>
        ) : null}
        <div className="input-wrapper">
          <textarea
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Describe what the feature does and why it’s important"
            required
          ></textarea>
        </div>
      </label>

      <label>
        Category:
        <select name="category" required onChange={(e) => setCategory(e.target.value)}>
          <option value="">Select Category</option>
          <option value="Design">Design (Appearance, Layout, or Visuals)</option>
          <option value="Functionality">Functionality (Features or How Things Work)</option>
          <option value="Performance">Performance (Speed or Responsiveness)</option>
          <option value="Integration">Integration (Connecting with Other Tools or Services)</option>
          <option value="Other">N/A, Other, or Unsure</option>
        </select>
      </label>

      <label>
        Priority Level:
        <select name="priority" required onChange={(e) => setPriority(e.target.value)}>
          <option value="">Select Priority</option>
          <option value="Low">Low</option>
          <option value="Normal">Normal</option>
          <option value="High">High</option>
        </select>
      </label>

      <div className="attachments-wrapper">
        <label htmlFor="attachments">Attachments (Optional):</label>
        <div className="input-wrapper">
          <input
            ref={fileInputRef}
            id="attachments"
            type="file"
            name="attachments"
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files);
              setAttachments(files);
              validateAttachments(files);
            }}
          />
        </div>
        <small>Max total size: 23 MB</small>
        {attachments.length > 0 && (
          <div className="attachments-preview">
            {attachments.map((file, index) => (
              <div key={index} className="attachment-item">
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="attachment-preview"
                  />
                ) : (
                  <div className="attachment-file">{file.name}</div>
                )}
                <div className="remove-attachment-wrapper">
                  <button
                    type="button"
                    className="remove-attachment"
                    onClick={() => {
                      const updatedAttachments = attachments.filter((_, i) => i !== index);
                      setAttachments(updatedAttachments);
                      validateAttachments(updatedAttachments);
                    }}
                  >
                    <i className="fa-solid fa-x" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <button
        type="submit"
        className={`submit-button ${isSubmitting ? "loading" : ""}`}
        disabled={!isFeatureNameValid || !isDescriptionValid || areAttachmentsValid === false || isSubmitting}
      >
        {isSubmitting ? (
          <>
            <i className="fa fa-spinner fa-spin"></i>&nbsp; Submitting Request...
          </>
        ) : (
          "Submit Request"
        )}
      </button>
      {submissionStatus && <p>{submissionStatus}</p>}
    </form>
  );
};

export default AddFeatureForm;