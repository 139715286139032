import React, { useState, useRef } from "react";
import axios from './../../api/axios';

const RemoveFeatureForm = () => {
  // State Management
  const [removeFeatureName, setRemoveFeatureName] = useState("");
  const [isRemoveFeatureNameValid, setIsRemoveFeatureNameValid] = useState(null);
  const [isTypingRemoveFeatureName, setIsTypingRemoveFeatureName] = useState(false);

  const [reasonForRemoval, setReasonForRemoval] = useState("");
  const [isReasonForRemovalValid, setIsReasonForRemovalValid] = useState(null);
  const [isTypingReasonForRemoval, setIsTypingReasonForRemoval] = useState(false);

  const [category, setCategory] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [areAttachmentsValid, setAreAttachmentsValid] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const fileInputRef = useRef(null);

  // Validation Functions
  const validateRemoveFeatureName = (value) => {
    setIsTypingRemoveFeatureName(true);
    setTimeout(() => {
      setIsTypingRemoveFeatureName(false);
      setIsRemoveFeatureNameValid(value.trim().length >= 3 && value.trim().length <= 150);
    }, 500);
  };

  const validateReasonForRemoval = (value) => {
    setIsTypingReasonForRemoval(true);
    setTimeout(() => {
      setIsTypingReasonForRemoval(false);
      setIsReasonForRemovalValid(value.trim().length >= 3 && value.trim().length <= 8000);
    }, 500);
  };

  const validateAttachments = (files) => {
    const totalSize = Array.from(files).reduce((sum, file) => sum + file.size, 0);
    setAreAttachmentsValid(totalSize <= 23 * 1024 * 1024); // 23 MB
  };

  // Handlers
  const handleRemoveFeatureNameChange = (e) => {
    const value = e.target.value;
    setRemoveFeatureName(value);
    validateRemoveFeatureName(value);
  };

  const handleReasonForRemovalChange = (e) => {
    const value = e.target.value;
    setReasonForRemoval(value);
    validateReasonForRemoval(value);
  };

  const handleRemoveFeatureSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("removeFeatureName", removeFeatureName);
    formData.append("reasonForRemoval", reasonForRemoval);
    formData.append("category", category);

    attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    try {
      const response = await axios.post("/api/remove-feature", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        setSubmissionStatus("Feature removal request submitted successfully!");

        // Reset all states
        setRemoveFeatureName("");
        setReasonForRemoval("");
        setCategory("");
        setAttachments([]);
        setIsRemoveFeatureNameValid(null);
        setIsReasonForRemovalValid(null);
        setAreAttachmentsValid(null);

        if (fileInputRef.current) fileInputRef.current.value = "";

        // Clear success message after 5 seconds
        setTimeout(() => setSubmissionStatus(""), 5000);
      }
    } catch (err) {
      console.error(err);
      const errorMessage = err.response?.data?.message || "An error occurred. Please try again later.";
      setSubmissionStatus(`Submission failed: ${errorMessage}`);

      // Clear error message after 5 seconds
      setTimeout(() => setSubmissionStatus(""), 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="feature-form" onSubmit={handleRemoveFeatureSubmit}>
      <label>
        Feature Name:
        {isTypingRemoveFeatureName ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : isRemoveFeatureNameValid ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : isRemoveFeatureNameValid === false ? (
          <i className="fa fa-times" style={{ color: "red" }}></i>
        ) : null}
        <div className="input-wrapper">
          <input
            type="text"
            name="removeFeatureName"
            value={removeFeatureName}
            onChange={handleRemoveFeatureNameChange}
            placeholder="E.g., Outdated Chart, Feedback Button"
            required
          />
        </div>
      </label>

      <label>
        Category:
        <select
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          <option value="">Select Category</option>
          <option value="Design">Design (Appearance, Layout, or Visuals)</option>
          <option value="Functionality">Functionality (Features or How Things Work)</option>
          <option value="Performance">Performance (Speed or Responsiveness)</option>
          <option value="Integration">Integration (Connecting with Other Tools or Services)</option>
          <option value="Other">N/A, Other, or Unsure</option>
        </select>
      </label>

      <label>
        Reason for Removal:
        {isTypingReasonForRemoval ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : isReasonForRemovalValid ? (
          <i className="fa fa-check" style={{ color: "green" }}></i>
        ) : isReasonForRemovalValid === false ? (
          <i className="fa fa-times" style={{ color: "red" }}></i>
        ) : null}
        <div className="input-wrapper">
          <textarea
            name="reasonForRemoval"
            value={reasonForRemoval}
            onChange={handleReasonForRemovalChange}
            placeholder="Explain why this feature is problematic or no longer needed"
            required
          ></textarea>
        </div>
      </label>

      <div className="attachments-wrapper">
        <label htmlFor="attachments">Attachments (Optional):</label>
        <div className="input-wrapper">
          <input
            ref={fileInputRef}
            id="attachments"
            type="file"
            name="attachments"
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files);
              setAttachments(files);
              validateAttachments(files);
            }}
          />
        </div>
        <small>Max total size: 23 MB</small>
        {attachments.length > 0 && (
          <div className="attachments-preview">
            {attachments.map((file, index) => (
              <div key={index} className="attachment-item">
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="attachment-preview"
                  />
                ) : (
                  <div className="attachment-file">{file.name}</div>
                )}
                <div className="remove-attachment-wrapper">
                  <button
                    type="button"
                    className="remove-attachment"
                    onClick={() => {
                      const updatedAttachments = attachments.filter((_, i) => i !== index);
                      setAttachments(updatedAttachments);
                      validateAttachments(updatedAttachments);
                    }}
                  >
                    <i className="fa-solid fa-x" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <button
        type="submit"
        className={`submit-button ${isSubmitting ? "loading" : ""}`}
        disabled={
          !isRemoveFeatureNameValid ||
          !isReasonForRemovalValid ||
          areAttachmentsValid === false ||
          isSubmitting
        }
      >
        {isSubmitting ? (
          <>
            <i className="fa fa-spinner fa-spin"></i>&nbsp; Submitting Request...
          </>
        ) : (
          "Submit Request"
        )}
      </button>

      {submissionStatus && <p>{submissionStatus}</p>}
    </form>
  );
};

export default RemoveFeatureForm;